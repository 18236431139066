.monster-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    max-height: 650px;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
}
