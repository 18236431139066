.create-monster {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.create-monster a {
    padding: 8px;
}

.create-monster form {
    display: flex;
    width: 420px;
    flex-direction: column;
    gap: 16px;
}
