.header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: #242424;
    box-shadow: 0 1px 2px #0003;
    color: #fff;
}

.header .options {
    display: flex;
    align-items: center;
    gap: 8px;
}

.header .link {
    color: #fff;
    text-decoration: none;
}
