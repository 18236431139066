.battle-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 16px;
}

.battle-ground {
    display: flex;
    width: 100%;
    height: 350px;
    max-height: 350px;
    align-items: center;
    justify-content: center;
}

.battle-field .battle-ground .img {
    width: 60%;
    height: 100%;
    background-image: url('../../assets/img/battle-ground.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-between;
}

.battle-field .battle-ground .battle {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.battle-field .battle-ground .battle .winner-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.battle-field .battle-ground .battle .winner-info .winner-title {
    background-color: #fff;
    display: inline;
    align-self: center;
    margin: 6px;
}

.battle-field .battle-ground .monster {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.battle-field .battle-ground .monster-container {
    display: flex;
    width: 20%;
    height: 100%;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
}

.battle-field .battle-ground .monster-container div {
    width: 100%;
}
